import React from 'react';
import { useLanguage } from '../LanguageContext';

const Home = () => {
  const { language } = useLanguage();

  return (
    <div>
            <section class="home section" id="home">
                <div class="home__container container grid">
                    <div>
                        <img src="assets/img/home.png" alt='background' class="home__img"></img>
                    </div>
                    
                    <div class="home__data">
                        <div class="home__header">
                            <h1 class="home__title">D3NMOH</h1>
                            {language === 'de' && <h2 class="home__subtitle">web-design</h2>}
                            {language === 'uk' && <h2 class="home__subtitle">веб-дизайн</h2>}
                            {language === 'ru' && <h2 class="home__subtitle">веб-дизайн</h2>}
                            {language === 'en' && <h2 class="home__subtitle">web-design</h2>}
                        </div>

                        <div class="home__footer">
                            {language === 'de' && <h3 class="home__title-description">Wer bin ich?</h3>}
                            {language === 'uk' && <h3 class="home__title-description">Хто я?</h3>}
                            {language === 'ru' && <h3 class="home__title-description">Кто я?</h3>}
                            {language === 'en' && <h3 class="home__title-description">Who I am?</h3>}
                            {language === 'de' && <p class="home__description">Ich bin einfach ein Mensch, der gerne schöne Dinge erschafft</p>}
                            {language === 'uk' && <p class="home__description">Я просто людина, яка любить створювати щось гарне</p>}
                            {language === 'ru' && <p class="home__description">Я просто человек, которому нравится создавать что-то красивое</p>}
                            {language === 'en' && <p class="home__description">I am simply a person who likes to create beautiful things</p>}
                        </div>
                    </div>
                </div>
            </section>
    </div>
  );
};

export default Home;