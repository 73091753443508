// LanguageSwitcher.js
import React, { useState } from 'react';
import './styles.css';
import { useLanguage } from './LanguageContext';

const LanguageSwitcher = ({ setSelectedContent }) => {
    const { language, switchLanguage } = useLanguage();
    const [isMenuVisible, setIsMenuVisible] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuVisible(!isMenuVisible);
    };
  
    const closeMenu = () => {
      setIsMenuVisible(false);
    };
  
    return (
        <div>
            <header class="header" id="header">
                <nav className="nav container">
                    <img class='nav__logo' src='./assets/img/logo.d3.svg'/>
                    <div class='lang'>
                        <img onClick={() => switchLanguage('de')} src='./assets/img/de.png' width='35px' />
                        <img onClick={() => switchLanguage('uk')} src='./assets/img/uk.png' width='35px' />
                        <img onClick={() => switchLanguage('ru')} src='./assets/img/ru.png' width='35px' />
                        <img onClick={() => switchLanguage('en')} src='./assets/img/en.png' width='35px' />
                    </div>
                    <div className={`nav__menu ${isMenuVisible ? 'show-menu' : ''}`} id="nav-menu">
                        <ul class="nav__list">
                            <li class="nav__item">
                                {language === 'de' && <a onClick={() => setSelectedContent('home')} class="nav__link">Startseite</a>}
                                {language === 'uk' && <a onClick={() => setSelectedContent('home')} class="nav__link">Головна</a>}
                                {language === 'ru' && <a onClick={() => setSelectedContent('home')} class="nav__link">Главная</a>}
                                {language === 'en' && <a onClick={() => setSelectedContent('home')} class="nav__link">Home</a>}
                            </li>
                            <li class="nav__item">
                                {language === 'de' && <a onClick={() => setSelectedContent('zifferblatt')} class="nav__link">Zifferblätter</a>}
                                {language === 'uk' && <a onClick={() => setSelectedContent('zifferblatt')} class="nav__link">Циферблати</a>}
                                {language === 'ru' && <a onClick={() => setSelectedContent('zifferblatt')} class="nav__link">Циферблаты</a>}
                                {language === 'en' && <a onClick={() => setSelectedContent('zifferblatt')} class="nav__link">WatchFaces</a>}
                            </li>
                            <li class="nav__item">
                                <button class="nav__link-sigma" onClick={() => setSelectedContent('sigmadroid')}>SigmaDroid</button>
                            </li>
                            <li class="nav__item">
                                <button class="nav__link-cafe" onClick={() => setSelectedContent('cafeenvivo')}>Cafe en Vivo</button>
                            </li>
                        </ul>
                        <div class="nav__close" id="nav-close" onClick={closeMenu}>
                            <i class="ri-close-line"/>
                        </div>
                    </div>
                    <div class="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                        <i class="ri-function-line"/>
                    </div>
                </nav>
            </header>
        </div>
    );
  };
  
  export default LanguageSwitcher;
  