// ContentContainer.js
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './styles.css'; // Импортируем основные стили
import './animations.css'; // Импортируем стили для анимаций
import LanguageSwitcher from './LanguageSwitcher';
import { LanguageProvider } from './LanguageContext';
import Home from './components/Home';
import Zifferblatt from './components/Zifferblatt';
import SigmaDroid from './components/SigmaDroid';
import CafeEnVivo from './components/CafeEnVivo';


const ContentContainer = () => {
  const [selectedContent, setSelectedContent] = useState('home');

  const renderContent = () => {
    switch (selectedContent) {
      case 'home':
        return <Home />;
      case 'zifferblatt':
        return <Zifferblatt />;
      case 'sigmadroid':
        return <SigmaDroid />;
      case 'cafeenvivo':
        return <CafeEnVivo />;
      default:
        return null;
    }
  };

  return (
    <LanguageProvider>
      <div>
        <LanguageSwitcher setSelectedContent={setSelectedContent} />

        <CSSTransition
          in={true} // Признак активации анимации
          appear={true} // Анимация будет срабатывать при первом появлении
          timeout={500} // Длительность анимации в миллисекундах
          classNames="fade" // Префикс для классов анимации
        >
          <div className="content-container">
            {/* Отображаем выбранное содержимое */}
            {renderContent()}
          </div>
        </CSSTransition>

        <footer className="footer section">
        <center>
            <a href="https://t.me/D3NMOH" target="_blank" class="footer__social-link tg__link">
              <img src="assets/img/kontaktieren.svg"></img>
            </a>
          </center>
          <p className="footer__copy">
            <a href="https://d3nmoh.xyz/" target="_blank" className="footer__copy-link">&#169; D3NMOH</a>
          </p>
        </footer>

        <a href="#" className="scrollup" id="scroll-up">
          <i className="ri-arrow-up-s-line scrollup__icon"></i>
        </a>
      </div>
    </LanguageProvider>
  );
};

export {ContentContainer, LanguageSwitcher};
