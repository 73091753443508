import React from 'react';
import { useLanguage } from '../LanguageContext';

const Zifferblatt = () => {
  const { language } = useLanguage();

  return (
    <div>
        <section class="watchface section" id="watchface">
            {language === 'de' && <h2 class="section__title section__title-gradient">Zifferblätter</h2>}
            {language === 'uk' && <h2 class="section__title section__title-gradient">Циферблати</h2>}
            {language === 'ru' && <h2 class="section__title section__title-gradient">Циферблаты</h2>}
            {language === 'en' && <h2 class="section__title section__title-gradient">WatchFaces</h2>}

            <div class="discount__container container grid weinachten">
                <img src="assets/img/xmas1.png" alt='xmas' class="discount__img4"/>
                <img src="assets/img/xmas3.png" alt='xmas-aod' class="discount__img3"/>
                <div class="discount__animate">
                    {language === 'de' && <h2 class="discount__title">Weihnachtszifferblatt</h2>}
                    {language === 'uk' && <h2 class="discount__title">Різдвяний циферблат</h2>}
                    {language === 'ru' && <h2 class="discount__title">Рождественский циферблат</h2>}
                    {language === 'en' && <h2 class="discount__title">Christmas Watch Face</h2>}

                    {language === 'de' && <p class="discount__description" style={{ marginBottom: '5px' }}>Ein Zifferblatt, das genau in die weihnachtliche Entourage passt!<br/>Weihnachtsbaum, Schnee, gestrickte Uhrschrift, Schneefallanimation und zwei Steckplätze für WearOS-Funktionen<br/><br/>Liebst du Weihnachten?<br/>Dann ist dies genau das Richtige für Sie!</p>}
                    {language === 'uk' && <p class="discount__description" style={{ marginBottom: '5px' }}>Циферблат, який ідеально впишеться до різдвяного антуражу!<br/>Ялинка, сніг, в'язаний шрифт годинника, анімація падаючого снігу та два слоти для функцій WearOS.<br/><br/>Любите Різдво?<br/>Тоді він для вас!</p>}
                    {language === 'ru' && <p class="discount__description" style={{ marginBottom: '5px' }}>Циферблат, который отлично впишется в рождественский антураж!<br/>Ёлка, снег, вязаный шрифт часов, анимация падающего снега и два слота для функций WearOS.<br/><br/>Любите Рождество?<br/>Тогда это для вас!</p>}
                    {language === 'en' && <p class="discount__description" style={{ marginBottom: '5px' }}>A watch face that will fit right in with the Christmas entourage!<br/>Christmas tree, snow, knitted clock font, falling snow animation and two slots for WearOS complications.<br/><br/>Do you love Christmas?<br/>Then this is for you!</p>}
                    <br/><br/>
                    <a href="https://d3nmoh.xyz/dl/xyz.d3nmoh.xmas.apk" class="button sig-but apkbutton">
                        {language === 'de' && <img src="assets/img/apk_xmas_de.png" alt='apk-xmas'/>}
                        {language === 'uk' && <img src="assets/img/apk_xmas_uk.png" alt='apk-xmas'/>}
                        {language === 'ru' && <img src="assets/img/apk_xmas_ru.png" alt='apk-xmas'/>}
                        {language === 'en' && <img src="assets/img/apk_xmas_en.png" alt='apk-xmas'/>}
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=xyz.d3nmoh.xmas&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' class="button sig-but apkbutton">
                        {language === 'de' && <img src="assets/img/gplay_de.png" alt='gplay-xmas'/>}
                        {language === 'uk' && <img src="assets/img/gplay_uk.png" alt='gplay-xmas'/>}
                        {language === 'ru' && <img src="assets/img/gplay_ru.png" alt='gplay-xmas'/>}
                        {language === 'en' && <img src="assets/img/gplay_en.png" alt='gplay-xmas'/>}
                    </a>
                </div>
            </div>
            <br/><br/><br/><br/>

            <div class="watchface__container container grid">
                <div>
                    <img src="assets/img/watchface.png" alt='horde-watchface' class="watchface__img"/>
                </div>

                <div class="watchface__data">
                    {language === 'de' && <p class="watchface__description">Zifferblatt im Horden-Stil für World of Warcraft-Fans für WearOS</p>}
                    {language === 'uk' && <p class="watchface__description">Циферблат для фанатів World of Warcraft у стилі Орди для WearOS</p>}
                    {language === 'ru' && <p class="watchface__description">Циферблат в стиле Орды для поклонников World of Warcraft для WearOS</p>}
                    {language === 'en' && <p class="watchface__description">Horde-style watch face for World of Warcraft fans for WearOS</p>}
                    <a href="https://d3nmoh.xyz/dl/xyz.d3nmoh.hordewatchface.apk" class="button button--flex" style={{ width: '220px', height: '70px'}}>
                        <img src="assets/img/wearos.png" alt='wearos' width="40px"/>
                        {language === 'de' && <p> APK direkt herunterladen</p>}
                        {language === 'uk' && <p> Завантажте APK безпосередньо</p>}
                        {language === 'ru' && <p> Скачать APK напрямую</p>}
                        {language === 'en' && <p> Download APK directly</p>}
                    </a>
                </div>
            </div>
        </section>

        <section class="discount section">
            <div class="discount__container container grid" style={{ backgroundColor: '#209f00', backgroundImage: 'url(assets/img/sigmadroid_d3_logo.svg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                <img src="assets/img/aod.png" alt='aod' class="discount__img1"/>
                <img src="assets/img/main.png" alt='watchface' class="discount__img2"/>
                <div class="discount__animate">
                    {language === 'de' && <h2 class="discount__title">SigmaDroid Zifferblatt</h2>}
                    {language === 'uk' && <h2 class="discount__title">Циферблат SigmaDroid</h2>}
                    {language === 'ru' && <h2 class="discount__title">Циферблат SigmaDroid</h2>}
                    {language === 'en' && <h2 class="discount__title">SigmaDroid Watch Face</h2>}

                    {language === 'de' && <p class="discount__description" style={{ marginBottom: '0'}}>Sieht immer gut und<br/>lesbar aus!<br/><br/>Vier slots<br/>für die Funktionen<br/>von WearOS<br/>auf Ihre Wahl</p>}
                    {language === 'uk' && <p class="discount__description" style={{ marginBottom: '0'}}>Виглядає завжди<br/>гарно та читабельно!<br/><br/>Чотири слоти<br/>для функцій<br/>WearOS<br/>на ваш вибір</p>}
                    {language === 'ru' && <p class="discount__description" style={{ marginBottom: '0'}}>Всегда хорошо<br/>выглядит и читается!<br/><br/>Четыре слота<br/>для функций<br/>WearOS<br/>по вашему выбору</p>}
                    {language === 'en' && <p class="discount__description" style={{ marginBottom: '0'}}>Always looks<br/>good and readable!<br/><br/>Four slots<br/>for the WearOS<br/>functions<br/>of your choice</p>}
                    <br/><br/>
                    <a href="https://d3nmoh.xyz/dl/xyz.d3nmoh.sigmadroid.apk" class="button sig-but apkbutton" width='200px' height='70px'>
                        
                        {language === 'de' && <img src="assets/img/apk_sigma_de.png" alt='apk'/>}
                        {language === 'uk' && <img src="assets/img/apk_sigma_uk.png" alt='apk'/>}
                        {language === 'ru' && <img src="assets/img/apk_sigma_ru.png" alt='apk'/>}
                        {language === 'en' && <img src="assets/img/apk_sigma_en.png" alt='apk'/>}
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=xyz.d3nmoh.sigmadroid&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' class="button sig-but apkbutton" style={{width: '200px', height: '70px', marginTop: '10px'}}>
                        {language === 'de' && <img src='assets/img/gplay_sigma_de.png' alt='gplay'/>}
                        {language === 'uk' && <img src='assets/img/gplay_sigma_uk.png' alt='gplay'/>}
                        {language === 'ru' && <img src='assets/img/gplay_sigma_ru.png' alt='gplay'/>}
                        {language === 'en' && <img src='assets/img/gplay_sigma_en.png' alt='gplay'/>}
                    </a>
                    <br/><br/>
                </div>
            </div>
        </section>
    </div>
  );
};

export default Zifferblatt;
