import React from 'react';
import { useLanguage } from '../LanguageContext';

const SigmaDroid = () => {
  const { language } = useLanguage();

  return (
    <div>
        <section class="discount section">
        <h2 class="section__title section__title-gradient-sigma">SigmaDroid</h2>
            <div class="discount__container container grid" style={{ border: '10px 10px 10px 10px', borderColor: '#209f00', backgroundColor: '#209f0010', backgroundImage: 'url(assets/img/sigmadroid_d3_logo.svg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                    <div class="sigmadroid__data">
                        <strong><i class="ri-paint-line sigmadroid__icon" style={{fontSize: '32px'}}/></strong>
                        {language === 'de' && <h3 class="sigmadroid__title">Palette der Farben</h3>}
                        {language === 'uk' && <h3 class="sigmadroid__title">Палітра кольорів</h3>}
                        {language === 'ru' && <h3 class="sigmadroid__title">Палитра цветов</h3>}
                        {language === 'en' && <h3 class="sigmadroid__title">Palette of colors</h3>}

                        {language === 'de' && <span class="sigmadroid__subtitle">Auswahl einer Farbpalette<br/>um Harmonie in<br/>einem Design zu erreichen</span>}
                        {language === 'uk' && <span class="sigmadroid__subtitle">Вибір палітри кольорів<br/>задля досягнення<br/>гармонії в дизайні</span>}
                        {language === 'ru' && <span class="sigmadroid__subtitle">Выбор цветовой палитры<br/>для достижения<br/>гармонии в дизайне</span>}
                        {language === 'en' && <span class="sigmadroid__subtitle">Selecting a color<br/>palette to achieve<br/>harmony in one design</span>}

                        <strong><i class="ri-brush-line sigmadroid__icon" style={{fontSize: '32px'}}/></strong>
                        {language === 'de' && <h3 class="sigmadroid__title">Gestaltung</h3>}
                        {language === 'uk' && <h3 class="sigmadroid__title">Дизайн сайту</h3>}
                        {language === 'ru' && <h3 class="sigmadroid__title">Дизайн сайта</h3>}
                        {language === 'en' && <h3 class="sigmadroid__title">Website design</h3>}

                        {language === 'de' && <span class="sigmadroid__subtitle">Entwicklung von Website-Design<br/>und Markenelementen für ROM</span>}
                        {language === 'uk' && <span class="sigmadroid__subtitle">Розробка дизайну сайту<br/>та елементів бренду для ROM</span>}
                        {language === 'ru' && <span class="sigmadroid__subtitle">Разработка дизайна сайта<br/>и элементов бренда для ROM</span>}
                        {language === 'en' && <span class="sigmadroid__subtitle">Development of website design<br/>and brand elements for ROM</span>}

                        <strong><i class="ri-plug-line sigmadroid__icon" style={{fontSize: '32px'}}/></strong>
                        {language === 'de' && <h3 class="sigmadroid__title">Technische<br/>Unterstützung</h3>}
                        {language === 'uk' && <h3 class="sigmadroid__title">Технічна<br/>підтримка</h3>}
                        {language === 'ru' && <h3 class="sigmadroid__title">Техническая<br/>поддержка</h3>}
                        {language === 'en' && <h3 class="sigmadroid__title">Technical<br/>support</h3>}

                        {language === 'de' && <span class="sigmadroid__subtitle">Hosting-Verwaltung<br/>und Domain-Registrierung</span>}
                        {language === 'uk' && <span class="sigmadroid__subtitle">Менеджмент хостингу<br/>та реєстрація домену</span>}
                        {language === 'ru' && <span class="sigmadroid__subtitle">Организация хостинга<br/>и регистрация домена</span>}
                        {language === 'en' && <span class="sigmadroid__subtitle">Hosting management<br/>and domain registration</span>}

                        <strong><i class="ri-earth-line sigmadroid__icon" style={{fontSize: '32px'}}/></strong>
                        {language === 'de' && <h3 class="sigmadroid__title">Mehrsprachigkeit</h3>}
                        {language === 'uk' && <h3 class="sigmadroid__title">Багатомовність</h3>}
                        {language === 'ru' && <h3 class="sigmadroid__title">Многоязычность</h3>}
                        {language === 'en' && <h3 class="sigmadroid__title">Multilingualism</h3>}

                        {language === 'de' && <span class="sigmadroid__subtitle">Übersetzung der Website ins<br/>Ukrainische, Russische und Deutsche</span>}
                        {language === 'uk' && <span class="sigmadroid__subtitle">Переклад сайту українською,<br/>російською та німецькою мовами</span>}
                        {language === 'ru' && <span class="sigmadroid__subtitle">Перевод веб-сайта на украинский,<br/>русский и немецкий языки</span>}
                        {language === 'en' && <span class="sigmadroid__subtitle">Translation of the website into<br/>Ukrainian, Russian and German</span>}
                    </div>
                    <div class="sigmadroid__img">
                        <img src="assets/img/sigmadroid_logo.svg" alt='sigmadroid-logo'/>
                        <div style={{height: '70px'}}>
                        {language === 'de' && <a href="https://sigmadroid.xyz" className='button sigma-button' ><i class="ri-external-link-line"/> Zur Website gehen</a>}
                        {language === 'uk' && <a href="https://sigmadroid.xyz" className='button sigma-button' ><i class="ri-external-link-line"/> Перейти до веб-сайту</a>}
                        {language === 'ru' && <a href="https://sigmadroid.xyz" className='button sigma-button' ><i class="ri-external-link-line"/> Перейти на сайт</a>}
                        {language === 'en' && <a href="https://sigmadroid.xyz" className='button sigma-button' ><i class="ri-external-link-line"/> Go to the website</a>}
                    </div>
                    </div>


            </div>
        </section>

    </div>
  );
};

export default SigmaDroid;
