import React from 'react';
import { useLanguage } from '../LanguageContext';

const CafeEnVivo = () => {
  const { language } = useLanguage();

  return (
    <div>
        <section class="discount section">
            <h2 class="section__title section__title-gradient-cafe">Cafe en Vivo</h2>
            <div class="discount__container container grid" style={{backgroundColor: '#4a2d26', backgroundImage: 'url(assets/img/Coffee.svg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                <div class="discount__animate">
                    {language === 'de' && <h2 class="cafeenvivo__text discount__title">Haus des Tees und des Kaffees</h2>}
                    {language === 'uk' && <h2 class="cafeenvivo__text discount__title">Дім чаю та кави</h2>}
                    {language === 'ru' && <h2 class="cafeenvivo__text discount__title">Дом чая и кофе</h2>}
                    {language === 'en' && <h2 class="cafeenvivo__text discount__title">House of tea and coffee</h2>}

                    {language === 'de' && <p class="cafeenvivo__text discount__description">Gemütliches und stimmungsvolles Kaffeehaus in der Ukraine</p>}
                    {language === 'uk' && <p class="cafeenvivo__text discount__description">Затишна та атмосферна кав'ярня в Україні</p>}
                    {language === 'ru' && <p class="cafeenvivo__text discount__description">Уютная и атмосферная кофейня в Украине</p>}
                    {language === 'en' && <p class="cafeenvivo__text discount__description">Cozy and atmospheric coffee house in Ukraine</p>}
                    <br/><br/>
                    <strong><i class="ri-paint-line sigmadroid__icon" style={{color: '#ddb66e', fontSize: '32px'}}/></strong>
                    {language === 'de' && <strong><p class="cafeenvivo__text discount__description"> Palette der Farben</p></strong>}
                    {language === 'uk' && <strong><p class="cafeenvivo__text discount__description">Палітра кольорів</p></strong>}
                    {language === 'ru' && <strong><p class="cafeenvivo__text discount__description">Палитра цветов</p></strong>}
                    {language === 'en' && <strong><p class="cafeenvivo__text discount__description">Palette of colors</p></strong>}

                    {language === 'de' && <p class="cafeenvivo__text discount__description">Auswahl eines sanften Farbschemas</p>}
                    {language === 'uk' && <p class="cafeenvivo__text discount__description">Підбір м'якої гами кольорів</p>}
                    {language === 'ru' && <p class="cafeenvivo__text discount__description">Подбор мягкой цветовой гаммы</p>}
                    {language === 'en' && <p class="cafeenvivo__text discount__description">Selection of soft color scheme</p>}

                    <strong><i style={{color: '#ddb66e', fontSize: '32px'}} class="ri-command-fill"/></strong>
                    {language === 'de' && <strong><p class="cafeenvivo__text discount__description"> Logo</p></strong>}
                    {language === 'uk' && <strong><p class="cafeenvivo__text discount__description"> Логотип</p></strong>}
                    {language === 'ru' && <strong><p class="cafeenvivo__text discount__description"> Логотип</p></strong>}
                    {language === 'en' && <strong><p class="cafeenvivo__text discount__description"> Logo</p></strong>}

                    {language === 'de' && <p class="cafeenvivo__text discount__description">Logogestaltung im Skevomorphismus-Stil</p>}
                    {language === 'uk' && <p class="cafeenvivo__text discount__description">Розробка логотипу у стилі скевоморфізму</p>}
                    {language === 'ru' && <p class="cafeenvivo__text discount__description">Разработка логотипа в стиле скевоморфизма</p>}
                    {language === 'en' && <p class="cafeenvivo__text discount__description">Logo design in skevomorphism style</p>}

                    <strong><i style={{color: '#ddb66e', fontSize: '32px'}} class="ri-tools-fill sigmadroid__icon"/></strong>
                    {language === 'de' && <strong><p class="cafeenvivo__text discount__description">Technische Unterstützung</p></strong>}
                    {language === 'uk' && <strong><p class="cafeenvivo__text discount__description">Технічна підтримка</p></strong>}
                    {language === 'ru' && <strong><p class="cafeenvivo__text discount__description">Техническая поддержка</p></strong>}
                    {language === 'en' && <strong><p class="cafeenvivo__text discount__description">Technical support</p></strong>}

                    {language === 'de' && <p class="cafeenvivo__text discount__description">Domänenregistrierung, Organisation des Hostings</p>}
                    {language === 'uk' && <p class="cafeenvivo__text discount__description">Реєстрація домену, організація хостингу</p>}
                    {language === 'ru' && <p class="cafeenvivo__text discount__description">Регистрация домена, организация хостинга</p>}
                    {language === 'en' && <p class="cafeenvivo__text discount__description">Domain registration, hosting organization</p>}
                    <br/><br/>
                    {language === 'de' && <a href="https://cafeenvivo.dp.ua/" class="button-cafe button button--flex" style={{height: '70px', color: '#ddb66e'}}><i class="ri-external-link-line"/> Zur Website gehen</a>}
                    {language === 'uk' && <a href="https://cafeenvivo.dp.ua/" class="button-cafe button button--flex" style={{height: '70px', color: '#ddb66e'}}><i class="ri-external-link-line"/> Перейти до веб-сайту</a>}
                    {language === 'ru' && <a href="https://cafeenvivo.dp.ua/" class="button-cafe button button--flex" style={{height: '70px', color: '#ddb66e'}}><i class="ri-external-link-line"/> Перейти на сайт</a>}
                    {language === 'en' && <a href="https://cafeenvivo.dp.ua/" class="button-cafe button button--flex" style={{height: '70px', color: '#ddb66e'}}><i class="ri-external-link-line"/> Go to the website</a>}
                </div>

                <img src="https://cafeenvivo.dp.ua/assets/img/home-coffee.png" alt='coffee' class="discount__img" style={{top: '1rem'}}/>
            </div>
        </section>
    </div>
  );
};

export default CafeEnVivo;
