import React from 'react';
import ReactDOM from 'react-dom';
import { ContentContainer } from './ContentContainer';
import { LanguageProvider } from './LanguageContext';

const App = () => {
  return (
    <LanguageProvider>
      <div>
        <ContentContainer />
      </div>
    </LanguageProvider>
  );
};

export default App;

ReactDOM.render(<App />, document.getElementById('app'));